@mixin generate-colors() {
    $colors: ('green', 'light-green', 'orange', 'white', 'red', 'blue', 'light-blue', 'grey', 'dark-grey');

    @each $color in $colors {
        .text-#{$color} {
            color: var(--#{$color});
        }

        .bg-#{"#{$color}"} {
            background-color: var(--#{$color});
        }

        .border-#{$color} {
            border: 1px solid var(--#{$color}); 
        }
    }
}

.nrm-blue-els-light {
    @extend .text-blue;

    &:hover, &:active {
        @extend .text-light-blue;
    }
}

.nrm-green-els-light {
    @extend .text-green;

    &:hover, &:active {
        @extend .text-light-green;
    }
}

@include generate-colors();