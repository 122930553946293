.modal {
    position: fixed;
    z-index: 1099;
    border-radius: 8px;
    z-index: 999;

    &.modal-full {
        background-color: rgb(0 0 0 / 20%);
        height: 100vh;
        top: 0;
        left: 0;
    }

    .modal-content {
        @extend .bg-white;
        border-radius: 8px;
        @extend .box-shadow;
        overflow: auto;

        &.modal-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}