$form-width: 400px;

form {
  @extend .p-0;
  flex-direction: column;

  .form-field {
    @extend .p-relative;
    margin-left: auto;
    margin-right: auto;
    
    .form-group {
      @extend .d-flex;
      @extend .fs-12;
      flex-direction: column;

      label {
        @extend .text-blue;
        @extend .fw-semibold;
        @extend .text-left;
      }

      input:not([type="checkbox"]), select, .file-input {
        @extend .h-35;
      }

      input, .file-input,
      textarea, select {
        border: 1px solid var(--light-blue);
        outline: none;
        @extend .br-5;
        @extend .fs-12;
        @extend .px-8;
        @extend .w-97;

        &.ng-invalid.ng-touched {
          border-color: var(--red);
        }
      }

      .file-input {
        @extend .d-flex;
        @extend .align-center;
        @extend .justify-content-center;
      }

      textarea {
        @extend .pt-8;
      }

      .suffix-icon {
        top: 55%;
        right: 5px;
      }

      .icon-container {
        right: 10px;
        top: 35px;
      }
    }
  }
}

.info-container {
  width: max-content;
  bottom: -6px;

  &.currency {
    text-transform: capitalize;
  }

  .display-number-container {
    i {

      &.show-right {
        right: -13px;
        @extend .b-0;
        @extend .fs-10;
      }

      &.show-top {
        @extend .r-0;
        bottom: 40px;
      }

      + .display-number-text {
        display: none;
      }
  
      &:hover + .display-number-text,
      &:focus + .display-number-text,
      &:active + .display-number-text {
        @extend .d-block;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
}

.warn-div {
  @extend .r-4;
  top: 14px;

  .warn-msg {
    top: -35px;
    right: -5px;
    width: max-content;
    @extend .px-4;
    @extend .py-8;
  }

  i {
    + .warn-msg {
      @extend .d-none;
    }

    &:hover + .warn-msg,
    &:focus + .warn-msg,
    &:active + .warn-msg {
      @extend .d-block;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator {
  @extend .b-0;
  @extend .r-0;
  @extend .t-0;
  @extend .l-0;
  @extend .p-absolute;
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
}