@mixin generate-padding() {
    @for $i from 0 through 16 {
        @if ($i % 4==0) {
            .p-#{$i} {
                padding: #{$i}px;
            }

            .pt-#{$i} {
                padding-top: #{$i}px;
            }

            .pr-#{$i} {
                padding-right: #{$i}px;
            }

            .pb-#{$i} {
                padding-bottom: #{$i}px;
            }

            .pl-#{$i} {
                padding-left: #{$i}px;
            }

            .px-#{$i} {
                @extend .pr-#{$i};
                @extend .pl-#{$i};
            }

            .py-#{$i} {
                @extend .pt-#{$i};
                @extend .pb-#{$i};
            }
        }
    }
}

@mixin generate-margin() {
    @for $i from 0 through 16 {
        @if ($i % 4==0) {

            .m-#{$i} {
                margin: #{$i}px;
            }

            .mt-#{$i} {
                margin-top: #{$i}px;
            }

            .mr-#{$i} {
                margin-right: #{$i}px;
            }

            .mb-#{$i} {
                margin-bottom: #{$i}px;
            }

            .ml-#{$i} {
                margin-left: #{$i}px;
            }

            .mx-#{$i} {
                @extend .mr-#{$i};
                @extend .ml-#{$i};
            }

            .my-#{$i} {
                @extend .mt-#{$i};
                @extend .mb-#{$i};
            }
        }
    }
}

@mixin generate-padding-margin-breakpoints($breakpoint) {
    @for $i from 0 through 16 {
        @if ($i % 4==0) {
            // .m-#{$breakpoint}-#{$i} {
            //     margin: #{$i}px;
            // }

            // .p-#{$breakpoint}-#{$i} {
            //     padding: #{$i}px;
            // }

            .mt-#{$breakpoint}-#{$i} {
                margin-top: #{$i}px;
            }

            // .pt-#{$breakpoint}-#{$i} {
            //     padding-top: #{$i}px;
            // }

            // .mr-#{$breakpoint}-#{$i} {
            //     margin-right: #{$i}px;
            // }

            // .pr-#{$breakpoint}-#{$i} {
            //     padding-right: #{$i}px;
            // }

            .mb-#{$breakpoint}-#{$i} {
                margin-bottom: #{$i}px;
            }

            // .pb-#{$breakpoint}-#{$i} {
            //     padding-bottom: #{$i}px;
            // }

            .ml-#{$breakpoint}-#{$i} {
                margin-left: #{$i}px;
            }

            // .pl-#{$breakpoint}-#{$i} {
            //     padding-left: #{$i}px;
            // }

            // .mx-#{$breakpoint}-#{$i} {
            //     @extend .mr-#{$breakpoint}-#{$i};
            //     @extend .ml-#{$breakpoint}-#{$i};
            // }

            // .px-#{$breakpoint}-#{$i} {
            //     @extend .pr-#{$breakpoint}-#{$i};
            //     @extend .pl-#{$breakpoint}-#{$i};
            // }

            // .my-#{$breakpoint}-#{$i} {
            //     @extend .mt-#{$breakpoint}-#{$i};
            //     @extend .mb-#{$breakpoint}-#{$i};
            // }

            // .py-#{$breakpoint}-#{$i} {
            //     @extend .pt-#{$breakpoint}-#{$i};
            //     @extend .pb-#{$breakpoint}-#{$i};
            // }
        }
    }
}

@mixin generate-position() {
    @for $i from 0 through 16 {
        @if ($i % 4==0) {
            .t-#{$i} {
                top: #{$i}px;
            }
            .b-#{$i} {
                bottom: #{$i}px;
            }
            .l-#{$i} {
                left: #{$i}px;
            }
            .r-#{$i} {
                right: #{$i}px;
            }
        }
    }
}

