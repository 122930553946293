@import 'assets/custom-styles/mixins.scss';
@import 'assets/custom-styles/colors.scss';
@import 'assets/custom-styles/variable.scss';
@import 'assets/custom-styles/font.scss';
@import 'assets/custom-styles/table.scss';
@import 'assets/custom-styles/form.scss';
@import 'assets/custom-styles/animation.scss';
@import 'assets/custom-styles/grid.scss';
@import 'assets/custom-styles/utils.scss';
@import 'assets/custom-styles/spacing.scss';
@import 'assets/custom-styles/button.scss';
@import 'assets/custom-styles/modal.scss';

* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    transition: 0.4s ease all;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
