:root {
    --blue: #0089CF;
    --green : #156234;
    --light-green : #e8eeea;
    --orange: #FF8500;
    --white: #FFFFFF;
    --red: #F80000;
    --light-blue: #AFB6E5;
    --grey: #E8EFEB;
    --dark-grey: #333;
}
