@include generate-padding();
@include generate-margin();
@include generate-position();

@media (max-width: 299px) {
    @include generate-padding-margin-breakpoints('xs');               
}

@media (min-width: 300px) and (max-width: 499px) {
    @include generate-padding-margin-breakpoints('sm');
}

@media (min-width: 500px) and (max-width: 767px) {
    @include generate-padding-margin-breakpoints('md');
}

@media (min-width: 768px) and (max-width: 1199px) {
    @include generate-padding-margin-breakpoints('lg');
}

@media (min-width: 1200px) {
    @include generate-padding-margin-breakpoints('xl');
}

.mt-per-2 {
    margin-top: 2% !important;
}

.mt-per-4 {
    margin-top: 4% !important;
}

.mt-per-5 {
    margin-top: 5% !important;
}

.mt-per-10 {
    margin-top: 10%;
}

.mt-per-30 {
    margin-top: 30%;
}

/* WIDTH */
.w-100 {
    width: 100% !important;
}

.w-95 {
    width: 95% !important;
}

.w-97 {
    width: 97.5%;
}

.w-98 {
    width: 98.5%;
}

.w-20-per {
    width: 20%;
}

.w-40 {
    width: 40% !important;
}

.w-30 {
    width: 30px;
}

.h-100 {
    height: 100%;
}

.h-35 {
    height: 35px;
}

.h-25 {
    height: 25px;
}

.h-60 {
    height: 60px;
}

.vh-100 {
    height: 100vh;
}

.t--6 {
    top: -6px;
}
