@mixin generate-grid-columns($device) {
  .hide-in-#{$device} {
      display: none;
  }

  $columns: 12;

  @for $i from 1 through $columns {
      .col-#{$device}-#{$i} {
          width: calc(100% / $columns) * $i;
      }
  }
}


/* Row Styles */
.row {
  @extend .d-flex;
  @extend .w-100;
  flex-wrap: wrap;
}

/* Column Styles */
[class^="col-"] {
  @extend .p-relative;
  float: left;
  min-height: 1px;
}

@media (max-width: 349px) {
  @include generate-grid-columns('xs');
}

@media (min-width: 350px) and (max-width: 576px) {
  @include generate-grid-columns('sm');
}

@media (min-width: 567px) and (max-width: 767px) {
  @include generate-grid-columns('md');
}

@media (min-width: 768px) and (max-width: 1199px) {
  @include generate-grid-columns('lg');
}

@media (min-width: 1200px) {
  @include generate-grid-columns('xl');
}

@media (max-width: 767px) {
  .hide-below-md {
    display: none !important;

    &.hide-img {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
}

@media (max-width: 1099px) {
  .hide-below-lg {
    display: none !important;

    &.hide-img {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
}