.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.p-fixed {
    position: fixed;
}

/*
--------------------------
--------------------------
TEXT ALIGNMENTS
--------------------------
--------------------------
*/

.text-uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.text-ellipsis {
    // max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.d-inline-block {
    display: inline-block;
}

.d-block {
    display: block !important;
}

/*
--------------------------
--------------------------
BORDER PROPERTIES
--------------------------
--------------------------
*/

.brd-0 {
    border: 0 !important;
}


.border {
    border: 1px solid var(--blue);
}

.dashed-border {
    border: 2px dashed var(--blue);
}

.br-5 {
    border-radius: 5px;
}

.br-4 {
    border-radius: 4px;
}

.box-shadow {
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

/*
--------------------------
--------------------------
FLEX PROPERTIES
--------------------------
--------------------------
*/

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.cur-pointer {
    cursor: pointer;
}

.overflow-x-auto {
    overflow-x: auto;
}