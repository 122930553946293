.table-header {
    @extend .fw-700;
    @extend .bg-light-green;
    @extend .fs-16;
    @extend .mb-4;
    @extend .text-left;
}

.md-table {
    border-collapse: collapse;
    overflow: hidden;
    @extend .w-100;

    th:not(.table-header) {
        @extend .bg-blue;
        @extend .text-white;
        @extend .fw-semibold;
        @extend .fs-14;
    }

    td {
        &:not(.bg-green):not(.bg-blue) {
            @extend .bg-white;
        }

        @extend .fs-12;
        @extend .fw-semibold;

        i.fa-trash {
            @extend .text-red;
        }

        &.amount-td {
            @extend .text-right;
        }
    }

    td, th {
        &:not(.no-border) {
            border: 1px solid var(--light-blue);
        }
        @extend .py-4;
        @extend .px-8;
    }
}
