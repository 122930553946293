.btn {
    @extend .align-center;
    @extend .cur-pointer;
    @extend .br-4;
    @extend .fw-semibold;
    @extend .brd-0;
    @extend .pl-4;
    @extend .pr-4;
    display: inline-flex;
    outline: none;
}

.btn-regular {
    @extend .btn;
    @extend .h-35;
    @extend .justify-content-center;
    min-width: 150px;
}

.btn-small {
    @extend .btn;
    @extend .fs-10;
    @extend .justify-content-center;
    @extend .h-25;
    min-width: 75px;
}
