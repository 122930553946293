@mixin generate-font-size() {
    @for $i from 8 through 40 {
        @if ($i % 2==0) {
            .fs-#{$i} {
                font-size: #{$i}px;
            }
        }
    }
}


@include generate-font-size();

.font {
    font-style: normal;
}

.fw-400 {
    font-weight: 400;
}

.fw-semibold {
    font-weight: 600;
    @extend .font;
}

.fw-700 {
    font-weight: 700;
}

.fw-900 {
    font-weight: 900;
}

